
// Libraries
import * as React from 'react'

// Services
import UserService from "../../../services/user"

// Components
import Layout from '../../../components/layout'
import DuoSection from '../../../components/duoSection'
import Seo from '../../../components/seo'
import InputFactory from '../../../components/inputFactory'
import Button from '../../../components/button'
import { navigate } from "gatsby"

// Context
import NotificationContext from '../../../contexts/notification'

class RegisterCarerImportedPage extends React.Component {
	state = {
		inputValues: {},
		inputErrors: {},
	}

	static contextType = NotificationContext

	render() {
		const {inputValues, inputErrors} = this.state

		return <Layout className="page--register page--register-imported register-imported nav-blue-half no-footer register-flow">
			<Seo title="Register imported" />
			<DuoSection RegisterImported>
				<div>
					<div className="content-wrap">
						<h1 className="color--endeavour">Complete Registration</h1>
					</div>
				</div>
				<div>
					<div className="content-wrap">
						<form onSubmit={this.handleSubmit}>
                        <InputFactory
								type="password"
                                label="Password*"
								name="password"
								placeholder="Min 6 characters"
								value={inputValues['password']}
								error={inputErrors['password']}
								onChange={this.handleInputChange}
								className="text-input"
							/>
                            <InputFactory
								type="password"
                                label="Confirm Password*"
								name="password_confirmation"
								placeholder="Min 6 characters"
								value={inputValues['password_confirmation']}
								error={inputErrors['password_confirmation']}
								onChange={this.handleInputChange}
								className="confirm-text-input"
							/>
							<Button type="submit" block className="register-button" fixedButton>Complete Registration</Button>
						</form>
					</div>
				</div>
			</DuoSection>
		</Layout>
	}

	handleInputChange = (value, name, scope) => {
		// Get current form data from state
		const {inputValues} = this.state

		if (value !== undefined) {
			// Update field value
			if (scope) {
				inputValues[scope][name] = value
			}
			else {
				inputValues[name] = value
			}
		} else {
			// Remove field value
			if (scope) {
				delete inputValues[scope][name]
			}
			else {
				delete inputValues[name]
			}
		}

		// Update state of file input values
		this.setState({ inputValues })
	}

	handleSubmit = async (event) => {
		event.preventDefault()
		const {inputValues} = this.state
		const {token} = this.props
		const {addNotification} = this.context

		inputValues['registration_token'] = token

		const userService = new UserService()
		await userService.setImportedUserPassword(inputValues)
			.then((response) => {
				if (response.success) {
					navigate('/register/carer/imported-success')
				} else {
					addNotification('...', 'error')
					this.setState({inputErrors: response.errors})
				}
			})
			.catch(err => console.error(err))
	}
}

export default RegisterCarerImportedPage
